<script>
import Layout from "../../layouts/horizontal";
const signalR = require("@microsoft/signalr");
import http from '@/http'

export default {
  components: {
    Layout
  },
  data() {
    return {
      screeningData: null,
      loading: false,
      errors:[],
      title: this.$t("pages.screening.title"),
      breadcrumbs: [
        {
          text: 'Marketplace',
          to: { name: 'indices' }
        },
        {
          text: this.$t("pages.screening.title"),
          active: true
        }
      ]
    };
  },
  props: {
    id: {
      type: String
    }
  },
    beforeDestroy(){
    if( this.connection){
       this.connection.stop();
       this.connection = null;
    }
  },
  created() {
    this.loading = true;
   
    const uri = process.env.VUE_APP_BASE_URL + "/client-notifications";
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(uri, { 
        accessTokenFactory: () => localStorage.getItem('access_token')
      })
      .withAutomaticReconnect()
      .build();

    this.connection.on("RefreshScreening", id => {

      if(this.id === id) {
        window.setTimeout(this.load, 5000);
        this.load();
      }
    });

    this.connection.onreconnecting(error => {
        console.log('signlaR onreconnecting connectionid: ' + this.connection.connectionId, error);
      });

    this.connection.onreconnected(() => {
        console.log('signlaR onreconnected connectionid: ' + this.connection.connectionId);
      });

    this.connection.start()
        .then(() => {
          this.load();
          console.log('signlaR connected! connectionid: ' + this.connection.connectionId)
      })
      .catch(console.error);
   
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
   
    async load() {
    
     this.loading = true;
     const { data } = await  http.get(`api/screening/${this.id}`);
      
     this.screeningData = data;
     this.loading = false;
      if(this.screeningData.request.calculation.status === 'PendingCalculation'
        || this.screeningData.request.calculation.status === 'CalculationInProcess') {
        return;
      }
      if(this.screeningData.request.screeningType === 'PerformanceStatistics') {
        let series = [];
        this.screeningData.request.indices.forEach(index => {
          const points = this.screeningData.result.performanceStat.values[index.indexId];
          series.push({
            showInLegend: true,
            name: index.indexDisplayName,
            data: (points || []).map(x => [x.timeStamp, x.value]),
            selected: true
          });
        });
        this.screeningData.result.performanceStat.chartOptions = {
          chart: {

          },
          legend:{
            enabled: true
          },
          exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                    "viewFullscreen", 
                    "printChart", 
                    "separator", 
                    "downloadPNG", 
                    "downloadJPEG", 
                    "downloadPDF", 
                    "downloadSVG",
                    "separator",
                    "downloadXLS",
                    "downloadCSV",
                    "viewData" ]
                }
            }
          },
          rangeSelector: {
              selected: 5
          },
          yAxis: {
            labels: {
              formatter: function () {
                return (this.value > 0 ? '' : '') + this.value;
              }
            },
            plotLines: [{
              value: 0,
              width: 2,
              color: 'silver'
            }]
          },
          plotOptions: {
            series: {
              showInNavigator: true,
              showCheckbox: true,
              events: {
              checkboxClick: function (event) {
                  if(event.checked) {
                    event.item.show();
                  } else {
                    event.item.hide();
                  }
                }
              },
            }
          },
          tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 2
          },
          series: series
        };
      } else if(this.screeningData.request.screeningType === 'RollingCorrelations') {
        this.screeningData.request.indices.forEach(row => {
          this.screeningData.request.indices.forEach(column => {
            const points = this.screeningData.result.rollingCorrelations.values[row.indexId + '-' + column.indexId];
            const chartOptions = {
              chart: {

              },

              exporting: {
                enabled: false
              },

              plotOptions: {
                series: {
                  marker: {
                    enabled: false
                  }
                }
              },

              title: {
                text: ''
              },

              xAxis: {
                type: 'datetime'
              },

              yAxis: {
              },

              series: [{
                showInLegend: false,
                name: '',
                data: points.map(x => [x.timeStamp, x.value])
              }]
            };
            this.screeningData.result.rollingCorrelations.values[row.indexId + '-' + column.indexId] = points && points.length > 0 ? chartOptions : null;
          });
        });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
              {{ title }} 
              <span class="mr-2 badge badge-info" v-if="screeningData && screeningData.request.calculation.status === 'PendingCalculation'">{{$t("pages.screening.pending-calculating")}}</span>
              <span class="mr-2 badge badge-primary" v-if="screeningData && screeningData.request.calculation.status === 'CalculationInProcess'">{{$t("pages.screening.calculation-in-process")}}</span>
              
              <span v-if="loading" class="ml-2"><i class="fa fa-spin fa-spinner"/></span>
              <br/>
              <small class="mr-2" v-if="screeningData">{{screeningData.request.createdAt | moment("ddd, MMM Do YYYY")}}</small>
            </span>
            
            
          </h4>

          <div class="page-title-right  d-none d-lg-block">
            <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
     <div class="row" v-if="screeningData && screeningData.request && screeningData.request.calculation.hasError">
      <div class="col">
        <div class="alert alert-danger">
          An error has occured. {{screeningData.request.calculation.errorMessage}}
        </div>
      </div>
    </div>
    <div class="row" v-if="screeningData">
      <div class="col-12">
          <div class="card  cardc8" >
            <div class="card-content">
              <div class="card-body" >
                <h5 class="card-title">{{$t('pages.screening.indices')}}
                
                </h5>
              
                <table class="table table-striped table-sm table-hover  mb-4">
                <tbody>
                  <tr v-for="i in screeningData.request.indices" :key="i.indexId">
                    <td>{{i.indexDisplayName}}</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
      </div>
    </div>
   
    <div class="row" v-if="screeningData && screeningData.request.screeningType === 'Correlations'">
      <div class="col-12">
          <div class="card cardc8" >
            <div class="card-content">
            <div class="card-body" >
              <h5 class="card-title">
                <span>{{$t('pages.screening.screeningType-Correlations')}}</span>
                <span class="ml-2" v-if="screeningData.request.years === 10">({{$t("pages.screening.10years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 1">({{$t("pages.screening.1year")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 3">({{$t("pages.screening.3years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 5">({{$t("pages.screening.5years")}})</span>
              </h5>
             
              <div class="table-responsive">
                <table class="table  table-sm  mb-4">
                  <tbody>
                    <tr>
                      <td></td>
                      <td v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center"><b>{{column.indexDisplayName}}</b></td>
                    </tr>
                    <tr v-for="row in screeningData.request.indices" :key="row.indexId">
                      <td><b>{{row.indexDisplayName}}</b></td>
                      <td v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center">
                        <span v-if="screeningData.request.calculation.status !== 'CalculationIsDone'">
                          <small class="text-muted"><i class="fa fa-spin fa-spinner"/></small>
                        </span>
                        <b v-if="screeningData.request.calculation.status === 'CalculationIsDone'">
                         
                          {{screeningData.result.correlations.values[row.indexId + '-' + column.indexId]}}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         </div>
      </div>
    </div>

    <div class="row" v-if="screeningData && screeningData.request.screeningType === 'RollingCorrelations'">
      <div class="col-12">
          <div class="card cardc8" >
            <div class="card-content">
            <div class="card-body" >
              <h5 class="card-title">
                <span>{{$t('pages.screening.screeningType-RollingCorrelations')}}</span>
                <span class="ml-2" v-if="screeningData.request.years === 10">({{$t("pages.screening.10years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 1">({{$t("pages.screening.1year")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 3">({{$t("pages.screening.3years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 5">({{$t("pages.screening.5years")}})</span>
              </h5>

              <span v-if="!screeningData.result || !screeningData.result.rollingCorrelations">
                <i class="fa fa-spin fa-spinner"/>
              </span>

              <div class="table-responsive" v-if="screeningData.result && screeningData.result.rollingCorrelations">
                <table class="table table-sm mb-4">
                  <tbody>
                    <tr>
                      <td style="min-width:150px; max-width:150px;"></td>
                      <td style="min-width:300px; max-width:300px;" v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center"><b>{{column.indexDisplayName}}</b></td>
                    </tr>
                    <tr v-for="row in screeningData.request.indices" :key="row.indexId">
                      <td style="min-width:150px; max-width:150px;"><b>{{row.indexDisplayName}}</b></td>
                      <td style="min-width:300px; max-width:300px; min-height:100px; max-height: 250px;height: 250px;" v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center">
                        <span v-if="screeningData.request.calculation.status !== 'CalculationIsDone'">
                          <small class="text-muted"><i class="fa fa-spin fa-spinner"/></small>
                        </span>
                        <div v-if="screeningData.request.calculation.status === 'CalculationIsDone'">
                         <highcharts 
                          v-if="screeningData.result.rollingCorrelations.values[row.indexId + '-' + column.indexId]"
                          :options="screeningData.result.rollingCorrelations.values[row.indexId + '-' + column.indexId]">
                        </highcharts>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>

    <div class="row" v-if="screeningData && screeningData.request.screeningType === 'PerformanceStatistics'">
      <div class="col-12">
          <div class="card cardc8" >
            <div class="card-content">
            <div class="card-body" >
              <h5 class="card-title">
                <span>{{$t('pages.screening.screeningType-PerformanceStatistics')}}</span>
                <span class="ml-2" v-if="screeningData.request.years === 10">({{$t("pages.screening.10years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 1">({{$t("pages.screening.1year")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 3">({{$t("pages.screening.3years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 5">({{$t("pages.screening.5years")}})</span>

                <span class="ml-4" v-if="screeningData.request.navOrReturn === 'Navs'">{{$t("pages.screening.navOrReturn-navs")}}</span>
                <span class="ml-4" v-if="screeningData.request.navOrReturn === 'Return'">{{$t("pages.screening.navOrReturn-return")}}</span>

                <span class="ml-4" v-if="screeningData.request.targetVolatilityEnabled">
                  {{$t("pages.screening.targetVolatility")}}: <span class="ml-2">{{screeningData.request.targetVolatility}}</span>
                </span>
              </h5>
              <span v-if="!screeningData.result || !screeningData.result.performanceStat">
                <i class="fa fa-spin fa-spinner"/>
              </span>
              <div class="table-responsive  mb-4" v-if="screeningData.result && screeningData.result.performanceStat">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <td></td>
                      <td><b>Annualised Return (%)</b></td>
                      <td><b>Realised Volatility (%)</b></td>
                      <td><b>Downside Volatility (%)</b></td>
                      <td><b>Max Drawdown (%)</b></td>
                      <td><b>Winning months (%)</b></td>
                      <td><b>Sharpe Ratio</b></td>
                      <td><b>Sortino Ratio</b></td>
                      <td><b>Calmar Ratio</b></td>
                    </tr>
                    <tr v-for="index in screeningData.request.indices" :key="index.indexId">
                      <td><b>{{index.indexDisplayName}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId] || {}).annualisedReturn || 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).realisedVolatility|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).downsideVolatility|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).maxDrawdown|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).winningMonths|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).sharpeRatio|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).sortinoRatio|| 0}}</b></td>
                      <td><b>{{(screeningData.result.performanceStat.statistic[index.indexId]|| {}).calmarRatio|| 0}}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mb-2">
                <i class="fa fa-info mr-2"/> <em class="text-muted "> Performance curves have been adjusted to equalise volatility, to give a fair comparison</em>
               
              </div>
               <highcharts 
                  v-if="screeningData.result && screeningData.result.performanceStat"
                  :constructorType="'stockChart'"
                  class="hc"
                  :options="screeningData.result.performanceStat.chartOptions">
                </highcharts>
            </div>
          </div>
           </div>
      </div>
    </div>

    <div class="row" v-if="screeningData && screeningData.request.screeningType === 'PerformanceData'">
      <div class="col-12">
          <div class="card cardc8" >
            <div class="card-content">
            <div class="card-body" >
              <h5 class="card-title">
                <span>{{$t('pages.screening.screeningType-PerformanceData')}}</span>
                  <span class="ml-2" v-if="screeningData.request.years === 10">({{$t("pages.screening.10years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 1">({{$t("pages.screening.1year")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 3">({{$t("pages.screening.3years")}})</span>
                <span class="ml-2" v-if="screeningData.request.years === 5">({{$t("pages.screening.5years")}})</span>

                <span class="ml-4" v-if="screeningData.request.navOrReturn === 'Navs'">{{$t("pages.screening.navOrReturn-navs")}}</span>
                <span class="ml-4" v-if="screeningData.request.navOrReturn === 'Return'">{{$t("pages.screening.navOrReturn-return")}}</span>

                <span class="ml-4" v-if="screeningData.request.targetVolatilityEnabled">
                  {{$t("pages.screening.targetVolatility")}}: <span class="ml-2">{{screeningData.request.targetVolatility}}</span>
                </span>
              </h5>
              <span v-if="!screeningData.result || !screeningData.result.performanceData">
                <i class="fa fa-spin fa-spinner"/>
              </span>
              <div class="table-responsive" v-if="screeningData.result && screeningData.result.performanceData">
                <table class="table  table-sm  mb-4">
                  <tbody>
                    <tr>
                      <td></td>
                      <td v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center"><b>{{column.indexDisplayName}}</b></td>
                    </tr>
                    <tr v-for="dt in screeningData.result.performanceData.values" :key="dt.date">
                      <td><b>{{dt.date | moment("L")}}</b></td>
                      <td v-for="column in screeningData.request.indices" :key="column.indexId" class="text-center">
                        <span v-if="screeningData.request.calculation.status !== 'CalculationIsDone'">
                          <small class="text-muted"><i class="fa fa-spin fa-spinner"/></small>
                        </span>
                        <b v-if="screeningData.request.calculation.status === 'CalculationIsDone'">
                          {{dt.data[column.indexId]}}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
             </div>
          </div>
      </div>
    </div>
  </Layout>
</template>